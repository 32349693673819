import React from 'react';
import { useBusiness } from 'hooks/business/use-business';
import { FeatureFlags } from 'utils/feature-flags';
import { IFeatureFlags } from 'utils/feature-flags/feature-flags';

interface FeatureFlagProps {
  disabled?: React.ReactElement;

  enabled?: React.ReactElement;

  flag: FeatureFlags;
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  disabled,
  enabled,
  flag,
}) => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  // if the env is true, the flag is on for everybody
  if (process.env[FeatureFlags[flag] as keyof IFeatureFlags] === 'true') {
    return enabled ? enabled : null;
  }

  if (isLoadingBusiness) {
    return null;
  }

  // if the business property exists and is true, the flag is on for that business
  if (business?.Properties?.[FeatureFlags[flag]]) {
    return enabled ? enabled : null;
  }

  // disabled is optional, return null otherwise
  if (disabled) {
    return disabled;
  }

  return null;
};
