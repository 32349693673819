import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import {
  AllocationPieChart,
  PieChartData,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import { PieChartText } from 'modules/portfolio/allocation/components/dashboard-entry-point/components/pie-chart-text/pie-chart-text';
import {
  Allocation,
  AllocationWithOther,
  treasureReserveProductKey,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { getTooltip } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import { tooltipList } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { AllocationNotification } from 'modules/portfolio/allocation/components/allocation-breakdown/components/allocation-notification/allocation-notification';
import { PortfolioStatus } from 'modules/portfolio/allocation/components/allocation-breakdown/components/portfolio-status/portfolio-status';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { ProductAllocationProducts } from 'modules/2023-q3/allocation/interface/product-allocation';
import { getReturnRangeText } from 'modules/2024-q1/return-range/utils/get-return-range-text';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { Styled } from './allocation-breakdown.style';

enum AllocationProducts {
  moneyMarket = 'moneyMarket',
  tbills = 'tbills',
  smart = 'smart',
}

export enum AllocationBreakdownVariant {
  Dashboard = 'Dashboard',
  Modal = 'Modal',
}

interface TooltipParameters {
  content?: React.ReactElement;
  eventLabel: string;
  header?: React.ReactElement;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const TableCell: React.FC = ({ children }) => {
  return (
    <td>
      <Box p={1}>{children}</Box>
    </td>
  );
};

const TableHeader: React.FC<{
  children: React.ReactNode;
  textAlign?: 'left' | 'right';
}> = ({ children, textAlign }) => {
  return (
    <Box textAlign={textAlign ?? 'right'}>
      <Styled.TableHeader
        color="stromboli"
        variant={TypographyVariant.LargerLabel}
      >
        {children}
      </Styled.TableHeader>
    </Box>
  );
};

const TableCellText: React.FC = ({ children }) => {
  return (
    <Box textAlign="right">
      <Styled.TableCell color="nero" variant={TypographyVariant.SubHeader3}>
        {children}
      </Styled.TableCell>
    </Box>
  );
};

interface AllocationBreakdownProps {
  allocation: Allocation | AllocationWithOther;

  allocationBalance?: number;

  allocationReturnPercentage?: number;

  aum: number;

  variant?: AllocationBreakdownVariant;
}

export const AllocationBreakdown: React.FC<AllocationBreakdownProps> = ({
  allocation,
  allocationBalance,
  allocationReturnPercentage,
  aum,
  variant,
}) => {
  const { hideProduct } = useHideProduct();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  // the total aum may not all be getting a return (or a return that's handled by this component)
  // therefore, if an allocationBalance is passed in, use that number to calculate amounts and returns
  const allocationAum = allocationBalance || aum;

  // if we have the blended rate from the api, use it, otherwise use the one calculated by allocation-logic
  const returnPercentage =
    allocationReturnPercentage || allocation[treasureReserveProductKey].return;
  // net return percentage accounts for the fee
  const netReturnPercentage =
    returnPercentage - allocation[treasureReserveProductKey].fee;

  const showFullView = variant === AllocationBreakdownVariant.Dashboard;
  const showNotifications = variant === AllocationBreakdownVariant.Dashboard;
  const showOther =
    variant === AllocationBreakdownVariant.Dashboard &&
    isFeatureFlagEnabled(
      FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED,
    );

  let AllocationProductsToDisplay = AllocationProducts;

  if (showOther && allocation['other' as keyof Allocation]?.allocation > 0) {
    const AllocationProductsOther = { other: 'Other' };

    AllocationProductsToDisplay = {
      ...AllocationProductsToDisplay,
      ...AllocationProductsOther,
    };
  }

  const getPieChartData = () => {
    const pieChartData: PieChartData[] = [];

    Object.keys(AllocationProductsToDisplay).map((productKey) => {
      return pieChartData.push({
        color: allocation[productKey as keyof Allocation].color,
        name: allocation[productKey as keyof Allocation].name,
        value: allocation[productKey as keyof Allocation].allocation,
      });
    });

    return pieChartData;
  };

  const getTooltipParameters = (allocationType: keyof Allocation) => {
    const params: TooltipParameters = {
      content: tooltipList(
        <Trans
          i18nKey={`${allocationType}TooltipContent`}
          ns={i18nNamespaceAllocationModal}
          components={{ ul: <ul />, li: <li /> }}
        />,
      ),
      eventLabel: allocationType,
      placement: 'right',
    };

    return params;
  };

  return (
    <>
      {showNotifications && (
        <AllocationNotification returnPercentage={returnPercentage} />
      )}

      <PortfolioStatus />

      <Grid container alignItems="flex-start" spacing={4}>
        <Grid item>
          <AllocationPieChart
            data={getPieChartData()}
            innerRadius="85%"
            innerText={
              <PieChartText
                allocationBalance={allocationBalance}
                aum={aum}
                returnPercentage={returnPercentage}
                netReturnPercentage={netReturnPercentage}
                showReturnAmount
              />
            }
            height="270px"
            width="270px"
          />
        </Grid>

        <Grid item xs>
          <Box mb={1}>
            <Typography variant={TypographyVariant.SubHeader1}>
              Breakdown of your portfolio
            </Typography>
          </Box>

          <Styled.Chart>
            <Styled.ChartHeader>
              <td />
              <TableCell>
                <TableHeader textAlign="left">Name</TableHeader>
              </TableCell>
              <TableCell>
                <TableHeader>Allocation</TableHeader>
              </TableCell>
              <TableCell>
                <TableHeader>Amount</TableHeader>
              </TableCell>

              {showFullView && (
                <>
                  <TableCell>
                    <TableHeader>Liquidity</TableHeader>
                  </TableCell>
                </>
              )}
            </Styled.ChartHeader>

            {Object.keys(AllocationProductsToDisplay).map((productKey) => {
              const product = allocation[productKey as keyof Allocation];

              const getProductAllocationProduct = (productKey: string) => {
                switch (productKey) {
                  case 'moneyMarket':
                    return ProductAllocationProducts.ManagedMoneyMarket;
                  case 'tbills':
                    return ProductAllocationProducts.ManagedTreasuries;
                  case 'smart':
                    return ProductAllocationProducts.ManagedIncome;
                }
              };

              const productAllocationProduct =
                getProductAllocationProduct(productKey);

              if (
                typeof productAllocationProduct !== 'undefined' &&
                hideProduct(productAllocationProduct)
              ) {
                return null;
              }

              return (
                <Styled.ChartRow>
                  <Styled.RowIndicator color={product.color} />

                  <TableCell>
                    <Grid container>
                      <Grid item>
                        <Styled.ProductName
                          variant={TypographyVariant.SubHeader1}
                        >
                          {product.name}
                        </Styled.ProductName>
                      </Grid>

                      <Grid item>
                        <Box position="relative" top="2px" ml={1}>
                          {getTooltip(
                            getTooltipParameters(
                              productKey as keyof Allocation,
                            ),
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell>
                    <TableCellText>
                      <Percentage
                        number={product.allocation}
                        decimalScale={product.allocation > 0 ? 1 : 0}
                        showExtremePrecision
                      />
                    </TableCellText>
                  </TableCell>

                  <TableCell>
                    <Box textAlign="right">
                      <Styled.Amount variant={TypographyVariant.BodySemiBold}>
                        <Currency
                          number={allocationAum * (product.allocation / 100)}
                          variant={CurrencyVariant.Full}
                        />
                      </Styled.Amount>
                    </Box>
                  </TableCell>

                  {showFullView && (
                    <>
                      <TableCell>
                        <TableCellText>
                          {product.liquidityString}{' '}
                          {`${product.liquidityUnit}${
                            product.liquidity > 1 ? 's' : ''
                          }`}
                        </TableCellText>
                      </TableCell>
                    </>
                  )}
                </Styled.ChartRow>
              );
            })}
          </Styled.Chart>
        </Grid>
      </Grid>
    </>
  );
};
