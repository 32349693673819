import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Styled } from './pie-chart-text.style';

interface PieChartTextProps {
  allocationBalance?: number;

  aum: number;

  returnPercentage: number;

  netReturnPercentage: number;

  showReturnAmount?: boolean;
}

export const PieChartText: React.FC<PieChartTextProps> = ({
  allocationBalance,
  aum,
  returnPercentage,
  showReturnAmount,
  netReturnPercentage,
}) => {
  // the total aum may not all be getting a return (or a return that's handled by this component)
  // therefore, if an allocationBalance is passed in, use that number to calculate amounts and returns
  const allocationAum = allocationBalance || aum;

  return (
    <Box pt={13} textAlign="center">
      <Typography color="gothic" variant={TypographyVariant.LargeLabel}>
        Total Balance
      </Typography>

      <Box mt={showReturnAmount ? 0.5 : 0} mb={showReturnAmount ? 0.5 : 1}>
        <Styled.AUM color="nero" variant={TypographyVariant.LargeLabel}>
          <Currency number={aum} variant={CurrencyVariant.Full} />
        </Styled.AUM>
      </Box>
    </Box>
  );
};
