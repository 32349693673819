import React from 'react';
import { Box } from '@material-ui/core';
import { i18n, Trans } from 'app/i18n';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
} from 'components/core';
import { CustodianStatuses, TypographyVariant } from 'enums';
import { useBusiness } from 'hooks/business/use-business';
import {
  NotificationBox,
  NotificationBoxVariant,
} from '../../../allocation-modal/components';
import Content from '../dashboard-entry.content.json';

i18n.addResourceBundle('en', 'dashboard-entry', Content);

interface Props {
  aum: number;

  pendingBalance: number;
}

export const AllocationNotificationNoPortfolio: React.FC<Props> = ({
  aum,
  pendingBalance,
}) => {
  const noFunds = aum === 0 && pendingBalance === 0;

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  let promptToShow;
  if (noFunds) {
    promptToShow = 'account set up no funds';
  } else if (pendingBalance > 0) {
    promptToShow = 'funds_on_way_alert';
  }

  if (isLoadingBusiness) {
    return null;
  }

  return (
    <>
      {promptToShow && (
        <Box mb={3}>
          <NotificationBox variant={NotificationBoxVariant.Information}>
            <Typography variant={TypographyVariant.SubHeader1}>
              <Trans
                i18nKey={promptToShow}
                ns="dashboard-entry"
                components={{
                  pendingBalance: (
                    <Currency
                      number={pendingBalance}
                      variant={CurrencyVariant.Full}
                    />
                  ),
                }}
              />
            </Typography>
          </NotificationBox>
        </Box>
      )}
    </>
  );
};
