import React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, Redirect, Route } from 'react-router-dom';
import { Spinner } from 'components/core';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { ErrorBoundary } from 'modules/page-errors/error-boundary';
import { GlobalServiceIssueShutdown } from 'modules/page-errors/global-service-issue-shutdown/global-service-issue-shutdown';
import { useTracking } from 'modules/tracking';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { isAllowedRole } from 'modules/2023-q4/rbac/utils/is-allowed-role';
import { isProhibitedRole } from 'modules/2023-q4/rbac/utils/is-prohibited-role';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

// react-query
import { useQueryClient } from 'react-query';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';

import { forceAccountIntake } from 'modules/onboarding/v3-streamlined-onboarding/utils/force-account-intake';
import { useStoreLoginMFA } from 'stores';

interface AppRouteProps {
  component: React.FC;
  idleSignedOut?: boolean;
  isSignedIn?: boolean;
  layout: React.FC;
  location?: {
    hash: string;
    pathname: string;
    search: string;
    state: {
      idleSignedOut?: boolean;
      redirect?: string;
    };
  };
  requireAuth?: boolean;
  requireNoAuth?: boolean;
  allowNoAuth?: boolean;
  title?: string;
  userPermissionsProhibited?: UserRole[];
  userPermissionsRequired?: UserRole[];
}

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  idleSignedOut,
  isSignedIn,
  layout: Layout,
  location,
  requireAuth,
  requireNoAuth,
  allowNoAuth,
  title,
  userPermissionsProhibited,
  userPermissionsRequired,
  ...rest
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const { Track } = useTracking({
    app: 'smb-portal',
    env: process.env.NODE_ENV,
    page: title,
    gloabalServiceIssue: isFeatureFlagEnabled(
      FeatureFlags.REACT_APP_GLOBAL_SERVICE_ISSUE_SHUTDOWN,
    ),
    maintenanceMode: isFeatureFlagEnabled(
      FeatureFlags.REACT_APP_2024_Q1_MAINTENANCE_MODE,
    ),
  });

  const queryClient = useQueryClient();
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: user, isLoading: isLoadingUser } = useUser(business?.Id);
  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useBankAccounts(business?.Id);

  const passedMFA = useStoreLoginMFA((state) => state.passedMFA);

  const overrideGlobalServiceIssueShutdown =
    business?.Properties?.overrideGlobalServiceIssueShutdown;

  const renderRoute = (props: RouteComponentProps) => {
    // if there is a service issue that requires an app shutdown,
    // don't let the user do anything
    if (
      isFeatureFlagEnabled(
        FeatureFlags.REACT_APP_GLOBAL_SERVICE_ISSUE_SHUTDOWN,
      ) &&
      !overrideGlobalServiceIssueShutdown
    ) {
      return (
        <Track>
          <GlobalServiceIssueShutdown maintenanceMode={false} />
        </Track>
      );
    }

    if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_2024_Q1_MAINTENANCE_MODE)) {
      return (
        <Track>
          <GlobalServiceIssueShutdown maintenanceMode />
        </Track>
      );
    }

    // user is trying to access an auth required page without signing in
    // record the path as redirect in state for after sign in
    if (requireAuth && !allowNoAuth && !isSignedIn) {
      // on sign out, remove all queries so no cached data shows up for a different login
      queryClient.removeQueries();

      return (
        <Redirect
          to={{
            pathname: RoutesPath.noAuth.signIn.path,
            state: {
              idleSignedOut,
              // eslint-disable-next-line react/prop-types
              redirect: `${props.location.pathname}${props.location.search}${props.location.hash}`,
            },
          }}
        />
      );
    }

    // excluding token route because those pages will handle the redirects and/or sign outs
    if (
      isSignedIn &&
      // eslint-disable-next-line react/prop-types
      props.match.path !== RoutesPath.onboarding.accountIntakeToken.path &&
      // eslint-disable-next-line react/prop-types
      props.match.path !==
        getVersionedPath({
          path: RoutesPath.onboarding.accountIntakeToken.path,
          version: 3,
        }) &&
      // eslint-disable-next-line react/prop-types
      props.match.path !== RoutesPath.noAuth.signInAccept.path &&
      // eslint-disable-next-line react/prop-types
      props.match.path !== RoutesPath.tasks.loginMFA.path
    ) {
      // user has not completed onboarding, AND not navigating to the accountIntakeToken page, so force them to account intake
      if (
        forceAccountIntake(business, bankAccounts) &&
        // eslint-disable-next-line react/prop-types
        props.match.path !== RoutesPath.onboarding.accountIntake.path &&
        // eslint-disable-next-line react/prop-types
        props.match.path !==
          getVersionedPath({
            path: RoutesPath.onboarding.accountIntake.path,
            version: 3,
          })
      ) {
        return <Redirect to={RoutesPath.onboarding.accountIntake.path} />;
      }

      // login MFA
      if (
        isFeatureFlagEnabled(
          FeatureFlags.REACT_APP_2023_Q3_LOGIN_MFA_ENABLED,
        ) &&
        !passedMFA && // eslint-disable-next-line react/prop-types
        props.match.path !== RoutesPath.tasks.loginMFA.path
      ) {
        // force user to enter MFA
        // pass through redirect for deep links, if exists
        if (requireNoAuth && location && location.state) {
          const { redirect } = location.state;

          return (
            <Redirect
              to={{
                pathname: RoutesPath.tasks.loginMFA.path,
                state: { redirect },
              }}
            />
          );
        } else if (location?.pathname) {
          return (
            <Redirect
              to={{
                pathname: RoutesPath.tasks.loginMFA.path,
                state: { redirect: location?.pathname },
              }}
            />
          );
        } else {
          return <Redirect to={RoutesPath.tasks.loginMFA.path} />;
        }
      }

      if (
        userPermissionsProhibited &&
        isProhibitedRole({
          prohibitedRoles: userPermissionsProhibited,
          roles: user.roles,
        })
      ) {
        // TODO: do we want to show a you do not have access to this page message?
        // redirect them home
        return <Redirect to={RoutesPath.pages.home.path} />;
      }

      if (
        isFeatureFlagEnabled(
          FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED,
        ) &&
        userPermissionsRequired &&
        !isAllowedRole({
          allowedRoles: userPermissionsRequired,
          role: user.role,
        })
      ) {
        // TODO: do we want to show a you do not have access to this page message?
        // redirect them home
        return <Redirect to={RoutesPath.pages.home.path} />;
      }

      // user is trying to go to a no auth page (excluding the accountIntakeToken page) while signed in
      if (requireNoAuth) {
        // if the user tried accessing a deep link, redirect them after sign in
        if (location && location.state) {
          const { redirect } = location.state;

          if (redirect) {
            return <Redirect to={redirect} />;
          }
        }

        // otherwise, send them home
        return <Redirect to={RoutesPath.pages.home.path} />;
      }
    }

    return (
      <Track>
        <Layout>
          <ErrorBoundary>
            <Component />
          </ErrorBoundary>
        </Layout>
      </Track>
    );
  };

  if (isLoadingBusiness || isLoadingUser || isLoadingBankAccounts) {
    return <Spinner />;
  }

  return <Route {...rest} render={renderRoute} />;
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  idleSignedOut: PropTypes.bool,
  layout: PropTypes.func.isRequired,
  requireAuth: PropTypes.bool,
  requireNoAuth: PropTypes.bool,
  title: PropTypes.string,
};

AppRoute.defaultProps = {
  requireAuth: false,
  requireNoAuth: false,
  title: undefined,
};

export default AppRoute;
