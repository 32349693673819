import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import firebase from 'utils/firebase';

/**
 * API Constants
 */

export const apiUrls = {
  auth: process.env.REACT_APP_API_URL_AUTH || '',
  banking: process.env.REACT_APP_API_URL_BANKING || '',
  business: process.env.REACT_APP_API_URL_BUSINESS || '',
  custodian: process.env.REACT_APP_API_URL_CUSTODIAN || '',
  portal: process.env.REACT_APP_API_URL_PORTAL || '',
};
export const API_CORS = process.env?.REACT_APP_CORS || '*';

/**
 * Axios API Instance
 */
const api = (
  selectedApi: keyof typeof apiUrls,
  options?: {
    authToken?: string;
    businessId?: string;
  },
): AxiosInstance => {
  const type = 'application/json';
  const apiUrl = apiUrls[selectedApi];

  const headers: AxiosRequestHeaders = {
    Accept: `${type}`,
    'Access-Control-Allow-Origin': API_CORS,
    'Content-Type': `${type}`,
    'X-Requested-With': 'XMLHttpRequest',
  };

  // TODO: retrieve businessId without hook
  if (options?.businessId) {
    headers['X-Business-Id'] = `${options?.businessId}`;
  }

  const customAxios = axios.create({
    baseURL: apiUrl,
    headers,
  });

  // Add a request interceptor
  customAxios.interceptors.request.use(async (request) => {
    const userAuthToken = await firebase.auth().currentUser?.getIdToken();

    if (!request.headers) {
      request.headers = {};
    }

    request.headers.Authorization = `Bearer ${
      options?.authToken || userAuthToken
    }`;

    return request;
  });

  // Add a response interceptor
  customAxios.interceptors.response.use(
    // Return a successful response back to the calling service
    (response) => response,
    // Return any error which is not due to authentication back to the calling service
    (error) => {
      if (error.response?.status !== 403) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    },
  );

  return customAxios;
};

export default api;
