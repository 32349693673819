import React, { useState } from 'react';
import { createCtx } from 'utils/create-ctx';
import { debugLogger } from 'utils/debug-logger';
import { useIdleTimer } from 'react-idle-timer';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useStoreAuthentication } from 'stores';

interface IdleContext {
  idleSignedOut: boolean;
}

export const [useIdleContext, IdleCtxProvider] = createCtx<IdleContext>();

export const IdleDetection: React.FC = ({ children }) => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();
  const { signOut } = useSignOut();
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  const idleDuration = 10; // minutes

  const [idleSignedOut, setIdleSignedOut] = useState(false);

  const onIdle = () => {
    const isDevEnv = ['development', 'local'].includes(process.env.NODE_ENV);

    if (isSignedIn) {
      if (isDevEnv) {
        debugLogger.warn(
          `Idle ${idleDuration} minutes\nIf you weren't a developer, you'd be logged out.`,
        );
      } else {
        // when idle, log the user out
        trackEvent({ eventName: TrackEventName.SignOutIdleTimeout });
        setIdleSignedOut(true);
        signOut();
      }
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * idleDuration,
    startOnMount: isSignedIn,
  });

  return (
    <IdleCtxProvider
      value={{
        idleSignedOut,
      }}
    >
      {children}
    </IdleCtxProvider>
  );
};
