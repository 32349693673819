import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { PolicyKey, TreasureFiLinks } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useLegalEntityTypes } from 'modules/onboarding/v3-streamlined-onboarding/hooks/get-legal-entity-types';
import { numberRegex } from 'components/core/forms/password/password-validations';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { PageTitle } from '../components/page-title';
import {
  AutocompleteController,
  CheckboxController,
  FormFieldContainer,
  SubmitButton,
  TextFieldController,
  formFields,
} from '../components/forms';
import {
  AddressForm,
  AddressFormSection,
  AddressFormSectionVariant,
} from '../components/forms/sections/address/address-form-section';

interface BusinessStructureForm extends AddressForm {
  businessTypeId: string;
  phoneNumber: string;
}

type IdType = { Id: string; Name: string };

const getOptions = (array: IdType[] = []) => {
  return array
    ?.sort((a: { Name: string }, b: { Name: string }) =>
      a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()),
    )
    .map?.((item: IdType) => {
      return { label: item.Name, value: item.Id };
    });
};

export const BusinessStructure: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.BusinessStructure,
  });

  const onboardingContext = useOnboardingContext();
  const savedOnboardingData = onboardingContext.onboardingResponse.data;
  const savedBusinessAddress = savedOnboardingData.business?.addresses?.[0];
  const savedAccountAgreements =
    savedOnboardingData.policiesKeysAgreed?.includes(
      PolicyKey.BankAccountAgreement,
    ) &&
    savedOnboardingData.policiesKeysAgreed?.includes(
      PolicyKey.InvestmentManagementAgreement,
    );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<BusinessStructureForm>();

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const { data: legalEntityTypes, isLoading: isLoadingLegalEntityTypes } =
    useLegalEntityTypes();

  const onSubmit = (data: BusinessStructureForm) => {
    const { businessTypeId, phoneNumber, ...address } = data;

    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        business: {
          addresses: [
            {
              ...address,
              id: savedBusinessAddress?.id,
            },
          ],
          businessTypeId,
          phoneNumber: phoneNumber
            ? phoneNumber.match(numberRegex)?.join('')
            : undefined,
          riskTolerance: 0,
        },
        policiesKeysAgreed: [
          PolicyKey.BankAccountAgreement,
          PolicyKey.InvestmentManagementAgreement,
        ],
      },
    });

    trackEvent({
      eventName: TrackEventName.IMAAgreed,
    });

    trackEvent({
      eventName: TrackEventName.BusinessClassificationCompleted,
    });

    trackEvent({
      eventName: TrackEventName.RiskProfileCompleted,
    });
  };

  useEffect(() => {
    onboardingContext.showLoading(isLoadingLegalEntityTypes);
  }, [isLoadingLegalEntityTypes]);

  if (!legalEntityTypes) {
    return null;
  }

  return (
    <>
      <PageTitle
        title="Business structure"
        description={
          <>
            This information about your business helps Treasure meet
            requirements from regulators and financial partners. By continuing
            you agree to our{' '}
            <a
              href={TreasureFiLinks.InvestmentManagementAgreement}
              onClick={() =>
                trackEvent({
                  eventName: TrackEventName.IMAOpened,
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              Investment Management Agreement
            </a>
            ,{' '}
            <FeatureFlag
              disabled={
                <>
                  <a
                    href={TreasureFiLinks.BankAccountAgreement}
                    onClick={() =>
                      trackEvent({
                        eventName: TrackEventName.BankAccountAgreementOpened,
                      })
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    Grasshopper Bank N.A. Account Agreement
                  </a>
                  ,{' '}
                </>
              }
              flag={FeatureFlags.REACT_APP_2024_Q3_GRASSHOPPER_SUNSET}
            />
            <a
              href={TreasureFiLinks.PrivacyPolicy}
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href={TreasureFiLinks.TermsOfUse}
              rel="noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
            .
          </>
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <CheckboxController
          {...formFields.accountAgreements}
          control={control}
          defaultValue={savedAccountAgreements || false}
        />

        <Box mt={3} mb={0.5}>
          <Typography color="nero" variant={TypographyVariant.LargerLabel}>
            Registered business address
          </Typography>
        </Box>

        <AddressFormSection
          address={savedBusinessAddress}
          control={control}
          isUS
          variant={AddressFormSectionVariant.Business}
        />

        <Box mt={3}>
          <FormFieldContainer>
            <TextFieldController
              {...formFields.businessPhoneNumber}
              control={control}
              defaultValue={savedOnboardingData.business?.phoneNumber}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <AutocompleteController
              {...formFields.businessTypeId}
              control={control}
              defaultValue={savedOnboardingData.business?.businessTypeId}
              options={getOptions(legalEntityTypes)}
            />
          </FormFieldContainer>
        </Box>

        <SubmitButton />
      </form>
    </>
  );
};
