import React from 'react';
import {
  i18nNamespace,
  AllocationSteps,
} from 'modules/2023-q3/allocation/allocation';
import { OnboardingSteps } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import { useTranslation } from 'app/i18n';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { TaskHeader } from 'modules/task/components/task-header/task-header';
import { TaskProgress } from 'modules/task/components/task-progress/task-progress';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import {
  HasMultipleAccounts,
  SwitchMultipleAccounts,
} from 'modules/onboarding/v3-streamlined-onboarding/layout/header/components/header-options/components';

interface IHeader {
  previousStep: () => void;

  step: OnboardingSteps;
}

export const Header: React.FC<IHeader> = ({ previousStep, step }) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation(i18nNamespace);
  const history = useHistory();
  const { signOut } = useSignOut();

  const getActiveStep = (step: OnboardingSteps) => {
    switch (step) {
      case OnboardingSteps.BusinessStructure:
        return 1;
      case OnboardingSteps.BusinessDetails:
        return 2;
      case OnboardingSteps.BusinessRepresentative:
        return 3;
      case OnboardingSteps.RareScenarios:
        return 4;
      case OnboardingSteps.BusinessOwners:
        return 5;
      case OnboardingSteps.Disclosures:
        return 6;
      case OnboardingSteps.BankDetails:
        return 7;
      default:
        return 0;
    }
  };

  const getButtonText = (getButtonTextProps?: { mobile?: boolean }) => {
    return t('Sign Out');
  };

  const handleSignOut = () => {
    return new Promise<void>((resolve) => {
      // finishes sign out processs
      signOut();
      resolve();
    }).then(() => {
      // and then track the event
      trackEvent({
        eventName: TrackEventName.IntakeSignOutClicked,
        // page: currentPage,
      });
    });
  };

  return (
    <MobileView
      mobile={
        <TaskHeader
          mobile
          button={
            <Button
              bgColor="brown6"
              bgHoverColor="brown4b"
              onClick={handleSignOut}
            >
              <Box px={3} py={1}>
                <Typography
                  color="black19"
                  noWrap
                  variant={TypographyVariant.Paragraph2}
                >
                  {getButtonText({ mobile: true })}
                </Typography>
              </Box>
            </Button>
          }
          taskProgress={
            <TaskProgress activeStep={getActiveStep(step)} steps={7} />
          }
          title={t('Registration')}
        />
      }
    >
      <TaskHeader
        button={
          <Grid
            alignItems="center"
            container
            justifyContent="flex-end"
            spacing={2}
          >
            {HasMultipleAccounts() && (
              <>
                <Grid item>
                  <SwitchMultipleAccounts>
                    <Button bgColor="brown6" bgHoverColor="brown4b">
                      <Box px={2} py={1}>
                        <Typography
                          color="black19"
                          variant={TypographyVariant.Paragraph2}
                        >
                          Switch business
                        </Typography>
                      </Box>
                    </Button>
                  </SwitchMultipleAccounts>
                </Grid>
              </>
            )}

            <Grid item>
              <Button
                bgColor="brown6"
                bgHoverColor="brown4b"
                onClick={handleSignOut}
              >
                <Box px={2} py={1}>
                  <Typography
                    color="black19"
                    variant={TypographyVariant.Paragraph2}
                  >
                    {getButtonText()}
                  </Typography>
                </Box>
              </Button>
            </Grid>
          </Grid>
        }
        taskProgress={
          <TaskProgress activeStep={getActiveStep(step)} steps={7} />
        }
        title={t('Registration')}
      />
    </MobileView>
  );
};
