import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Currency, CurrencyVariant, Percentage } from 'components/core';
import { ColumnType, wrapTableText } from 'modules/dashboard/components';
import { SectionContainerTitle } from 'modules/dashboard/components';
import { TableHeader } from 'modules/dashboard/components/dashboard-table/components/table-header/table-header';
import { TableRow } from 'modules/dashboard/components/dashboard-table/components/table-body/components/table-row/table-row';
import { ProductData } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/portfolio-view';
import {
  Product,
  ProductPositions,
} from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-positions/product-positions';
import { ProductPositions as ProductPositionsV2 } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-positions/product-positions-v2';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { TreasureFiEmails } from 'enums';
import { ProductAllocationProducts } from 'modules/2023-q3/allocation/interface/product-allocation';
import { getReturnRangeText } from 'modules/2024-q1/return-range/utils/get-return-range-text';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { ProductLabel } from './components/product-label/product-label';
import { Styled } from './product-table.style';

const columnsProduct: ColumnType[] = [
  { name: 'Asset', size: 6 },
  { alignment: 'center', name: 'Share', size: 1 },
  { alignment: 'center', name: 'Liquidity', size: 1 },
  { alignment: 'center', name: 'Amount', size: 3 },
  //  { alignment: 'right', name: 'Net Expected 12M Return', size: 2 },
  { alignment: 'right', size: 1 },
];

type ProductTableData = [
  React.ReactElement,
  React.ReactElement,
  React.ReactElement | null,
  React.ReactElement,
  // React.ReactElement | null,
  React.ReactElement | null,
];

interface ProductTableProps {
  data: ProductData[];
}

export const ProductTable: React.FC<ProductTableProps> = ({ data }) => {
  const { trackEvent } = useTracking<{
    component: string;
    eventName: TrackEventName;
    hasDrillDown: boolean;
    product: Product;
  }>({
    component: 'Portfolio Product Table',
  });

  const { hideProduct } = useHideProduct();

  const handleTableRowClick = (productRowData: {
    hasPositions: boolean;
    product: Product;
  }) => {
    trackEvent({
      eventName: TrackEventName.Click,
      hasDrillDown: productRowData.hasPositions,
      product: productRowData.product,
    });
  };

  const displayAccordion = (productRowData: {
    hasPositions: boolean;
    product: Product;
    tableData: ProductTableData;
  }) => {
    let allocationType = '';

    switch (productRowData.product) {
      case Product.Cash:
        allocationType = 'cash';
        break;
      case Product.ManagedMoneyMarket:
        allocationType = 'moneyMarket';
        break;
      case Product.ManagedTreasuries:
        allocationType = 'tbills';
        break;
      case Product.ManagedIncome:
        allocationType = 'smart';
        break;
      case Product.Other:
        allocationType = 'other';
        break;
      default:
    }

    return (
      <>
        <Styled.Accordion elevation={0}>
          <Styled.AccordionSummary>
            <Box width="100%" px={2}>
              <TableRow
                columns={columnsProduct}
                data={productRowData.tableData}
                onClick={() => handleTableRowClick(productRowData)}
              />
            </Box>
          </Styled.AccordionSummary>

          <Styled.AccordionDetails>
            <Box p={2}>
              {allocationType && (
                <Box mb={2}>
                  <Trans
                    components={{
                      supportEmail: (
                        <ContactTreasureSupportLink
                          subject={
                            productRowData.product === Product.Other
                              ? 'Uninvested Cash'
                              : productRowData.product
                          }
                        >
                          {TreasureFiEmails.Support}
                        </ContactTreasureSupportLink>
                      ),
                    }}
                    i18nKey={`${allocationType}TooltipContent`}
                    ns={i18nNamespaceAllocationModal}
                  />
                </Box>
              )}

              <FeatureFlag
                disabled={<ProductPositions product={productRowData.product} />}
                enabled={
                  <ProductPositionsV2 product={productRowData.product} />
                }
                flag={
                  FeatureFlags.REACT_APP_2024_Q2_ALLOCATION_BREAKDOWN_BY_PRODUCT
                }
              />
            </Box>
          </Styled.AccordionDetails>
        </Styled.Accordion>
      </>
    );
  };

  const displayProductRow = (productRowData: {
    hasPositions: boolean;
    product: Product;
    tableData: ProductTableData;
  }) => {
    if (productRowData.hasPositions) {
      return displayAccordion(productRowData);
    }

    return (
      <Box width="100%" px={2}>
        <TableRow
          columns={columnsProduct}
          data={productRowData.tableData}
          onClick={() => handleTableRowClick(productRowData)}
        />
      </Box>
    );
  };

  const products: {
    hasPositions: boolean;
    product: Product;
    tableData: ProductTableData;
  }[] = [];

  const addProduct = ({
    productAllocation,
    productAmount,
    productColor,
    productLiquidity,
    productName,
    // productRate,
    productType,
  }: {
    productAllocation: number;
    productAmount: number;
    productColor: string;
    productLiquidity?: string;
    productName: string;
    // productRate?: number;
    productType: Product;
  }) => {
    const hasPositions =
      productAmount > 0 &&
      (productType === Product.ManagedMoneyMarket ||
        productType === Product.ManagedTreasuries ||
        productType === Product.ManagedIncome ||
        productType === Product.Other); // TODO: is this a valid assumption?

    const getProductAllocationProduct = (productType: Product) => {
      switch (productType) {
        case Product.ManagedMoneyMarket:
          return ProductAllocationProducts.ManagedMoneyMarket;
        case Product.ManagedTreasuries:
          return ProductAllocationProducts.ManagedTreasuries;
        case Product.ManagedIncome:
          return ProductAllocationProducts.ManagedIncome;
      }
    };

    const productAllocationProduct = getProductAllocationProduct(productType);

    if (hideProduct(productType)) {
      return;
    }

    return products.push({
      hasPositions,
      product: productType,
      tableData: [
        wrapTableText(<ProductLabel color={productColor} name={productName} />),
        wrapTableText(<Percentage number={productAllocation} />),
        productLiquidity ? wrapTableText(productLiquidity) : null,
        wrapTableText(
          <Currency number={productAmount} variant={CurrencyVariant.Full} />,
        ),
        /*productRate && productAllocationProduct
          ? wrapTableText(
              getReturnRangeText(productAllocationProduct, productRate),
            )
          : productRate
          ? wrapTableText(
              <Percentage
                decimalScale={2}
                number={productRate * 100}
                showExtremePrecision
              />,
            )
          : null,*/
        hasPositions ? (
          <Box position="relative" top={3}>
            <Styled.AccordionIcon />
          </Box>
        ) : null,
      ],
    });
  };

  data.map((product) => addProduct(product));

  const managedProducts = products.filter(
    (product) => product.product !== Product.Cash,
  );

  const cashProducts = products
    .filter((product) => product.product === Product.Cash)
    .map((productData) => displayProductRow(productData));

  return (
    <>
      <Box px={2}>
        <SectionContainerTitle title="Treasure Managed" />

        <TableHeader columns={columnsProduct} />
      </Box>

      {managedProducts.map((productData) => displayProductRow(productData))}
    </>
  );
};
