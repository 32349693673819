import { OnboardingStatus } from 'enums';

export const forceAccountIntake = (
  business: {
    AddedInitialSweep: boolean | null;
    OnboardingStatus: OnboardingStatus;
  },
  bankAccounts: [],
) => {
  const completedOnboardingForm =
    business?.OnboardingStatus === OnboardingStatus.COMPLETE;
  const hasBankAccounts = bankAccounts?.length > 0;

  const canAccessDashboard = completedOnboardingForm && hasBankAccounts;

  return !canAccessDashboard;
};
