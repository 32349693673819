import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Heading,
  Pagination,
  Select,
  Spinner,
  Tabs,
  Text,
} from 'components/core';

import { ReactComponent as DocumentIconStatement } from 'assets/icons/documents/statement.svg';
import { ReactComponent as DocumentIconTax } from 'assets/icons/documents/tax.svg';
import { ReactComponent as DocumentIconTrade } from 'assets/icons/documents/trade.svg';

// flags
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

// layout
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useDocuments as getCustodianDocuments } from 'modules/documents/hooks/get-documents';
import { useDocuments } from 'modules/documents/hooks/use-documents';

import { ApexDocumentType } from 'enums';
import { ApexDocument } from 'types';
import { Styled } from './documents.style';

enum DocumentGroup {
  All,
  Statements,
  Trades,
  Taxes,
}

type YearOption = { label: string; value: string };

const ALL_YEARS_OPTION = { label: 'All', value: 'all' };

const Documents: React.FC = () => {
  const loadingContext = useLoadingContext();
  const paginationSize = 6;

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: initDocuments = [], isLoading: isLoadingDocuments } =
    useDocuments(business?.Id);

  // TODO: this appends the documents from custodian to those returned by api-portal-gateway
  // when api-portal-gatway returns all documents, we can remove this code
  const [documents, setDocuments] = useState<ApexDocument[]>();

  const {
    data: custodianDocuments = [],
    isLoading: isLoadingCustodianDocuments,
  } = getCustodianDocuments(business?.Id);

  // // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const custodianDocumentsJsonString = JSON.stringify(custodianDocuments);
  const initDocumentsJsonString = JSON.stringify(initDocuments);

  useEffect(() => {
    const parsedCustodianDocuments = JSON.parse(custodianDocumentsJsonString);
    const parsedInitDocuments = JSON.parse(initDocumentsJsonString);
    setDocuments([...parsedCustodianDocuments, ...parsedInitDocuments]);
  }, [custodianDocumentsJsonString, initDocumentsJsonString]);

  const [displayDocuments, setDisplayDocuments] = useState(documents);
  const [currentPage, setCurrentPage] = useState(1);
  const [year, setYear] = useState(ALL_YEARS_OPTION.value);
  const [sort, setSort] = useState('mostRecent');
  const [years, setYears] = useState<YearOption[]>([ALL_YEARS_OPTION]);
  const [documentGroup, setDocumentGroup] = useState(DocumentGroup.All);

  const documentItem = (document: ApexDocument) => {
    const formatDocumentDate = (date: Date) => {
      return dayjs(date).format('YYYY-MM-DD');
    };

    const getDocumentIcon = (documentType: ApexDocumentType) => {
      switch (documentType) {
        case ApexDocumentType.AccountStatement:
        case ApexDocumentType.TreasureCashStatement:
          return <DocumentIconStatement />;
        case ApexDocumentType.TradeConfirmation:
          return <DocumentIconTrade />;
        default:
          return <DocumentIconTax />;
      }
    };

    return (
      <a href={document.url} target="_blank" rel="noreferrer">
        <Styled.DocumentGridItem>
          {getDocumentIcon(document.documentType)}
          <Divider />
          <Box p={1}>
            <Box mb={0.25}>
              <Styled.DocumentName color="black" variant={2}>
                {document.name}
              </Styled.DocumentName>
            </Box>
            <Text color="black" variant={1}>
              {formatDocumentDate(document.date)}
            </Text>
          </Box>
        </Styled.DocumentGridItem>
      </a>
    );
  };

  const documentTab = () => {
    const getDocumentsString = () => {
      let message;

      switch (documentGroup) {
        case DocumentGroup.All:
          message = 'Documents';
          break;
        case DocumentGroup.Statements:
          message = 'Statements';
          break;
        case DocumentGroup.Trades:
          message = 'Trade Documents';
          break;
        case DocumentGroup.Taxes:
          message = 'Tax Documents';
          break;
      }

      return message;
    };

    return (
      <>
        {displayDocuments && displayDocuments.length === 0 ? (
          <Text variant={3}>
            No {getDocumentsString()} {year === 'all' ? '' : `from ${year}`}
          </Text>
        ) : (
          <Grid container spacing={5}>
            {displayDocuments &&
              displayDocuments
                .slice(
                  (currentPage - 1) * paginationSize,
                  currentPage * paginationSize,
                )
                .map((document, index) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      key={index.toString()}
                    >
                      <>{documentItem(document)}</>
                    </Grid>
                  );
                })}
          </Grid>
        )}
      </>
    );
  };

  const sortDocuments = (documents: ApexDocument[], sort: string) => {
    const displayDocuments = [...documents];
    if (sort === 'mostRecent') {
      displayDocuments.sort((a, b) =>
        dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1,
      );
    } else {
      displayDocuments.sort((a, b) =>
        dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1,
      );
    }

    return displayDocuments;
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSort(event.target.value as string);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
  };

  const handleTabChange = (tab: number) => {
    switch (tab) {
      case 0:
        setDocumentGroup(DocumentGroup.All);
        break;
      case 1:
        setDocumentGroup(DocumentGroup.Statements);
        break;
      case 2:
        setDocumentGroup(DocumentGroup.Trades);
        break;
      case 3:
        setDocumentGroup(DocumentGroup.Taxes);
        break;
    }
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  const updateYearsOptionsBasedOnDocuments = () => {
    if (!documents || !documents.length) return [];

    const yearsFromDocuments = documents.map((document) => {
      return new Date(document.date).getFullYear();
    });

    const allYears = Array.from(new Set(yearsFromDocuments)).sort().reverse();
    const yearOptions = allYears.map((y) => ({
      label: String(y),
      value: String(y),
    }));

    setYears([ALL_YEARS_OPTION, ...yearOptions]);
  };

  const filterDocuments = (documentTypes: ApexDocumentType[] | undefined) => {
    return (
      documents &&
      documents
        .filter((x) => {
          return documentTypes !== undefined
            ? documentTypes.includes(x.documentType)
            : x;
        })
        .filter((x) => {
          const allYearsSelected = year === ALL_YEARS_OPTION.value;
          const documentDateIsEqualSelectedYear =
            dayjs(x.date).year().toString() === year;

          return allYearsSelected || documentDateIsEqualSelectedYear;
        })
    );
  };

  const updateListOfDocuments = () => {
    let documentTypes: ApexDocumentType[] | undefined;

    if (documents && documents.length) {
      switch (documentGroup) {
        case DocumentGroup.All:
          documentTypes = undefined;
          break;
        case DocumentGroup.Statements:
          documentTypes = [
            ApexDocumentType.AccountStatement,
            ApexDocumentType.TreasureCashStatement,
          ];
          break;
        case DocumentGroup.Trades:
          documentTypes = [ApexDocumentType.TradeConfirmation];
          break;
        case DocumentGroup.Taxes:
          documentTypes = [
            ApexDocumentType['1099C'],
            ApexDocumentType['1099R'],
            ApexDocumentType['1042S'],
            ApexDocumentType['5498FMV'],
            ApexDocumentType['5498ESA'],
            ApexDocumentType.FMV,
            ApexDocumentType['1099Q'],
            ApexDocumentType.SDIRA,
          ];
          break;
      }

      const filteredDocuments = filterDocuments(documentTypes);

      if (filteredDocuments) {
        setDisplayDocuments(sortDocuments(filteredDocuments, sort));
        setCurrentPage(1);
      }
    }
  };

  const tabData = [
    {
      label: 'All',
      content: documentTab(),
    },
    {
      label: 'Statements',
      content: documentTab(),
    },
    {
      label: 'Trades',
      content: documentTab(),
    },
    {
      label: 'Taxes',
      content: documentTab(),
    },
  ];

  useEffect(() => {
    updateYearsOptionsBasedOnDocuments();
    updateListOfDocuments();
  }, [documents, documentGroup, sort, year]);

  useEffect(() => {
    loadingContext.setLoading(
      isLoadingCustodianDocuments || isLoadingDocuments,
    );
  }, [isLoadingCustodianDocuments, isLoadingDocuments, loadingContext]);

  if (loadingContext.loading) {
    return <Spinner />;
  }

  return (
    <CenteredContainer>
      <Box p={{ xs: 2, sm: 5 }}>
        <Box mb={0.75}>
          <Heading color="black" variant={2}>
            Custodial Documents
          </Heading>
        </Box>

        <Box mb={3}>
          <Heading color="black" variant={3}>
            Information from our custodial bank about your holdings.
          </Heading>
        </Box>

        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item>
              <Select
                label="Year"
                onChange={handleYearChange}
                options={years}
                value={year}
              />
            </Grid>

            <Grid item>
              <Select
                label="Sort By"
                onChange={handleSortChange}
                options={[
                  { label: 'Most Recent', value: 'mostRecent' },
                  { label: 'Oldest', value: 'oldest' },
                ]}
                value={sort}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <Tabs tabData={tabData} onChange={handleTabChange} />
        </Box>

        {displayDocuments && (
          <Pagination
            count={Math.ceil(displayDocuments.length / paginationSize)}
            onChange={handlePaginationChange}
            page={currentPage}
          />
        )}
      </Box>
    </CenteredContainer>
  );
};

export { Documents };
