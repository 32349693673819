import React, { useState } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Box, Divider, Drawer, Grid } from '@material-ui/core';
import { Percentage, Typography, TypographyVariant } from 'components/core';
import { ProductAllocationProducts } from 'modules/2023-q3/allocation/interface/product-allocation';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { getReturnRangeText } from 'modules/2024-q1/return-range/utils/get-return-range-text';
import { SidebarProductInformation } from './components/sidebar-product-information/sidebar-product-information';
import { Styled } from './more-information.style';

interface MoreInformationProps {
  product: ProductAllocationProducts;
  rate: number;
}

export const MoreInformation: React.FC<MoreInformationProps> = ({
  product,
  rate,
}) => {
  const { trackEvent, Track } = useTracking<{
    clickElement?: string;
    clickText?: string;
    component: string;
    drawerDirection: string;
    eventName: TrackEventName;
    product: ProductAllocationProducts;
  }>({
    component: 'More Information',
    product,
  });

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawer = ({
    clickElement,
    clickText,
    drawerDirection,
  }: {
    clickElement?: string;
    clickText?: string;
    drawerDirection: boolean;
  }) => {
    setOpenDrawer(drawerDirection);

    trackEvent({
      clickElement,
      clickText,
      drawerDirection: drawerDirection ? 'open' : 'close',
      eventName: TrackEventName.Click,
    });
  };

  return (
    <>
      <Box my={2}>
        <Divider />
      </Box>

      <Grid container justifyContent="space-between">
        <Grid item>
          <Styled.Link
            onClick={() =>
              handleDrawer({ clickText: 'More info', drawerDirection: true })
            }
          >
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              More info
            </Typography>
          </Styled.Link>
        </Grid>

        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            <SVGBox
              cursor="pointer"
              onClick={() =>
                handleDrawer({
                  clickElement: 'Caret Right Icon',
                  drawerDirection: true,
                })
              }
            >
              <Styled.CaretRight />
            </SVGBox>
          </Typography>
        </Grid>
      </Grid>

      <Styled.DrawerContainer>
        <Drawer
          anchor="right"
          BackdropComponent={Styled.Backdrop}
          onClose={() =>
            handleDrawer({ clickElement: 'Backdrop', drawerDirection: false })
          }
          open={openDrawer}
          PaperProps={{ elevation: 0 }}
        >
          <Box height="100%" maxWidth={400} minWidth={{ xs: '100vw', md: 400 }}>
            <Track>
              <SidebarProductInformation
                closeDrawer={() =>
                  handleDrawer({
                    clickElement: 'Close Icon', // this assumes it only being used by the close icon
                    drawerDirection: false,
                  })
                }
                product={product}
              />
            </Track>
          </Box>
        </Drawer>
      </Styled.DrawerContainer>
    </>
  );
};
