import React from 'react';
import { i18n, Trans } from 'app/i18n';
import { Box, Grid } from '@material-ui/core';
import {
  Percentage,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

import {
  initialAllocation,
  AllocationProducts,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { AllocationPieChart } from 'modules/portfolio/allocation/components/allocation-modal/components';
import { PieChartText } from 'modules/portfolio/allocation/components/dashboard-entry-point/components/pie-chart-text/pie-chart-text';
import { Styled } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal.style';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Container } from '../container/container';

import { AllocationNotificationNoPortfolio } from '../allocation-notification-no-portfolio/allocation-notification-no-portfolio';
import Content from './set-treasure-reserve-allocation.content.json';

const i18nNamespaceSetTreasureReserveAllocation =
  'set-treasure-reserve-allocation';

i18n.addResourceBundle(
  'en',
  i18nNamespaceSetTreasureReserveAllocation,
  Content,
);

interface AllocationProps {
  aum: number;
  pendingBalance: number;
}

export const SetTreasureReserveAllocation: React.FC<AllocationProps> = ({
  aum,
  pendingBalance,
}) => {
  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.PortfolioAllocationModal,
  });

  const noFunds = aum === 0 && pendingBalance === 0;

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);

  const returnPercentage = portfolios?.rates.cashRate * 100;

  if (isLoadingBusiness || isLoadingPortfolios) {
    return null;
  }

  if (!returnPercentage) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <AllocationNotificationNoPortfolio
        aum={aum}
        pendingBalance={pendingBalance}
      />
      <Grid container alignItems="center" spacing={5}>
        <Grid item>
          <AllocationPieChart
            data={[
              {
                color: noFunds ? 'gainsboro0' : 'primary',
                name: '',
                value: 100,
              },
            ]}
            innerRadius="80%"
            innerText={
              <PieChartText
                aum={aum}
                returnPercentage={noFunds ? 0 : returnPercentage}
                // NOTE: The net return percentage accounts for fees. However because cash product currently doesn't have fees, we can just use the value of returnPercentage
                netReturnPercentage={noFunds ? 0 : returnPercentage}
              />
            }
            height="200px"
            width="200px"
          />
        </Grid>

        <Grid item>
          <Box mb={3}>
            <Typography variant={TypographyVariant.HeaderRegular}>
              <Trans
                i18nKey={noFunds ? 'no funds' : 'your funds will receive'}
                ns={i18nNamespaceSetTreasureReserveAllocation}
              />
            </Typography>

            <Typography variant={TypographyVariant.Header}>
              <Trans
                i18nKey={noFunds ? 'earn up to' : 'increase your rate'}
                ns={i18nNamespaceSetTreasureReserveAllocation}
              />
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item>
              <Link
                onClick={() =>
                  trackEvent({
                    eventName:
                      TrackEventName.SeeRecommendedPortfolioButtonClicked,
                    section: TrackPageSection.PortfolioAllocation,
                  })
                }
                to={RoutesPath.tasks.allocation.path}
              >
                <Styled.RoundedButton>
                  <Trans
                    i18nKey="See Recommended Portfolio"
                    ns={i18nNamespaceSetTreasureReserveAllocation}
                  />
                </Styled.RoundedButton>
              </Link>
            </Grid>

            {noFunds && treasureReserve?.custodianAccountNumber && (
              <Grid item>
                <Link
                  onClick={() =>
                    trackEvent({
                      eventName: TrackEventName.TransferFundsButtonClicked,
                    })
                  }
                  to={RoutesPath.tasks.deposit.path}
                >
                  <Styled.RoundedButton variant="outlined">
                    <Trans
                      i18nKey="Transfer funds"
                      ns={i18nNamespaceSetTreasureReserveAllocation}
                    />
                  </Styled.RoundedButton>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
