import { FeatureFlags, IFeatureFlags } from 'utils/feature-flags/feature-flags';
import { useBusiness } from 'hooks/business/use-business';

export const useFeatureFlags = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const isFeatureFlagEnabled = (flag: FeatureFlags) => {
    const flagAsString = FeatureFlags[flag];

    // if the env is true, the flag is on for everybody
    if (process.env[flagAsString as keyof IFeatureFlags] === 'true') {
      return true;
    }

    if (isLoadingBusiness) {
      return false;
    }

    // if the business property exists and is true, the flag is on for that business
    if (business?.Properties?.[flagAsString]) {
      return true;
    }

    return false;
  };

  return { isFeatureFlagEnabled };
};
