import treasureApi from 'api';

interface PlaidTokenData {
  user: {
    clientUserId: string;
  };
  clientName: string;
  products?: string[];
  language: string;
  webhook: string;
  countryCodes: string[];
  linkCustomizationName?: string;
  bankItemId?: string;
  redirectUri?: string;
}

export const createLinkToken = async (
  businessId: string,
  clientUserId: string,
  customizationName?: string,
  bankItemId?: string,
  forceOauthPopup?: boolean,
): Promise<{ link_token: string }> => {
  const postURL = bankItemId ? '/get-link-update-token' : '/get-link-token';
  const postData: PlaidTokenData = {
    clientName: process.env.REACT_APP_PLAID_CLIENT_NAME!,
    countryCodes: process.env.REACT_APP_PLAID_COUNTRY_CODES?.split(',')!,
    language: process.env.REACT_APP_PLAID_LANGUAGE!,
    user: {
      clientUserId,
    },
    webhook: process.env.REACT_APP_PLAID_WEBHOOK_URL!,
    redirectUri: process.env.REACT_APP_PLAID_REDIRECT_URI,
  };

  if (bankItemId) {
    postData.bankItemId = bankItemId;
  } else {
    postData.products = process.env.REACT_APP_PLAID_PRODUCTS?.split(',');
  }

  if (customizationName) {
    postData.linkCustomizationName = customizationName;
  }

  // by removing the redirect uri, plaid will default to poping a new window
  if (forceOauthPopup) {
    delete postData.redirectUri;
  }

  const { data } = await treasureApi('banking', {
    businessId,
  }).post(postURL, postData);

  return data;
};
