import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import { useFootnoteContext } from 'components/layouts';
import { TreasureFiLinks } from 'enums';
import { i18n, useTranslation, Trans } from 'app/i18n';
import { Typography, TypographyVariant } from 'components/core';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { Styled } from './footer.style';
import Content from './footer.content.json';

i18n.addResourceBundle('en', 'footer', Content);

export interface FooterProps {
  dividerColor?: string;

  linkColor?: string;

  showProductsDisclosure?: boolean;

  textColor?: string;

  topDivider?: boolean;
}

export const Footer: React.FC<FooterProps> = ({
  dividerColor,
  linkColor,
  showProductsDisclosure,
  textColor,
  topDivider,
}) => {
  const { t } = useTranslation('footer');
  const { footnotes } = useFootnoteContext();

  return (
    <footer>
      {topDivider && (
        <Box mb={6}>
          <Styled.Divider />
        </Box>
      )}

      <Box maxWidth={900} mx="auto" pb={2}>
        {footnotes && (
          <Box mb={2}>
            {footnotes.map((footnote, index) => {
              return (
                <Text color={textColor} variant={2} key={index.toString()}>
                  {footnote.symbol} {footnote.description}
                </Text>
              );
            })}
          </Box>
        )}

        <Typography color={textColor} variant={TypographyVariant.Paragraph3}>
          <Box mb={2}>{t('copyright')}</Box>

          <Box mb={2}>
            <Trans
              t={t}
              i18nKey="owned and operated"
              components={{
                linkTermsOfUse: (
                  <a
                    href={TreasureFiLinks.TermsOfUse}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                ),
                linkPrivacyPolicy: (
                  <a
                    href={TreasureFiLinks.PrivacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                ),
              }}
            />
          </Box>

          <Box mb={2}>{t('informational purposes')}</Box>

          <Box mb={2}>
            <Trans
              t={t}
              i18nKey="registered"
              components={{
                linkFormADVBrochure: (
                  <a
                    href="https://www.treasurefi.com/adv-brochure"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Form ADV Brochure
                  </a>
                ),
              }}
            />
          </Box>

          <Box mb={2}>
            <Trans
              t={t}
              i18nKey="custodian"
              components={{
                linkSIPC: (
                  <a
                    href="https://www.sipc.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    sipc.org
                  </a>
                ),
              }}
            />
          </Box>

          {showProductsDisclosure && (
            <>
              <Box mb={2}>
                Past performance is not a guarantee of future results. Reliance
                on hypothetical performance, back-tested performance, and
                projected returns has risk. Projected performance shown is
                subject to a number of limitations, and is derived using
                assumptions designed to determine the probability or likelihood
                of a particular investment outcome based on a range of possible
                outcomes. It is possible that any of those assumptions may prove
                not to be accurate. In addition, performance of any one strategy
                when paired with another strategy may differ materially from
                investment gains projected, described, or otherwise referenced
                in forward-looking statements. Projected returns shown are based
                on the performance of the underlying securities of the portfolio
                and do not consider all factors that could impact performance of
                your account, such as security availability, money movement, and
                hold periods. For more information on the risks associated with
                hypothetical performance, our investment methodology, or how
                these figures were computed, please contact{' '}
                <ContactTreasureSupportLink>
                  support@treasure.tech
                </ContactTreasureSupportLink>
                .
              </Box>
            </>
          )}
        </Typography>
      </Box>
    </footer>
  );
};
