import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Withdrawal as WithdrawalV1 } from 'modules/2023-q3/deposit-withdrawal/withdrawal/withdrawal';
import { Withdrawal as WithdrawalV2 } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/withdrawal/withdrawal';
import { useBusiness } from 'hooks/business/use-business';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

export const WithdrawalTransition = () => {
  const { data: business } = useBusiness();

  return (
    <FeatureFlag
      disabled={<WithdrawalV1 />}
      enabled={<WithdrawalV2 />}
      flag={FeatureFlags.REACT_APP_2024_Q1_SCHEDULED_TRANSFERS}
    />
  );
};
