import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// TODO: if we want to use useSendEvent, turn this file into a hook
import mixpanel from 'modules/tracking/analytics/mixpanel';
import { TrackEventName, useSendEvent } from 'modules/tracking';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENVIRONMENT || '';
// Sentry recommends lowering trace sample rate in prod
// https://develop.sentry.dev/sdk/performance/
const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE || '';

const processBreadcrumb = (breadcrumb: any, hint: any) => {
  if (breadcrumb.category === 'ui.click') {
    breadcrumb.message = 'Clicked - ';
    const { target } = hint?.event;
    if (target?.ariaLabel) {
      breadcrumb.message += target.ariaLabel;
    } else if (target?.innerText) {
      breadcrumb.message += target.innerText;
    }
  }
  return breadcrumb;
};

export const initSentry = (history: any) => {
  try {
    if (SENTRY_DSN && SENTRY_ENV && SENTRY_SAMPLE_RATE) {
      const INTEGRATIONS: any[] = [];

      if (history) {
        INTEGRATIONS.push(
          new Integrations.BrowserTracing({
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
        );
      }

      Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENV,
        autoSessionTracking: true,
        integrations: INTEGRATIONS,
        // breadcrumb filters
        beforeBreadcrumb(breadcrumb, hint) {
          return processBreadcrumb(breadcrumb, hint);
        },
        // this is the default number of breadcrumbs to keep track of
        maxBreadcrumbs: 100,
        // traces sample rate has to be a decimal
        tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE),
      });
    } else {
      throw new Error('Missing required Sentry variables. Sentry disabled.');
    }
  } catch (error) {
    // swallow the error and move on
    console.error(error);
  }
};

export const sentryLog = ({
  error,
  userFacingError,
  customTag,
  errorLevel = Sentry.Severity.Error,
}: {
  error: Error;
  userFacingError?: string;
  customTag?: string;
  errorLevel?: Sentry.Severity;
}) => {
  Sentry.withScope((scope) => {
    scope.setTag('custom-error-tag', customTag);
    scope.setTag('user-facing-error', userFacingError);
    scope.setLevel(errorLevel);
    Sentry.captureException(error);
  });
};

export const setSentryUser = (user?: { email?: string | null }) => {
  if (user?.email) {
    Sentry.setUser({ email: user.email });
  }
};
