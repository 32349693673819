import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { Spinner } from 'components/core';
import {
  SectionContainer,
  SectionContainerTitle,
} from 'modules/dashboard/components';
import { ResponsiveScrollContent } from 'modules/dashboard/components/responsive-scroll-content/responsive-scroll-content';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { TableHeader } from 'modules/dashboard/components/dashboard-table/components/table-header/table-header';
import { Product } from './components/product-positions/product-positions';
import { ProductPieChart } from './components/product-pie-chart/product-pie-chart';
import { ProductTable } from './components/product-table/product-table';
import { Styled } from './portfolio-view.style';

const getPercentage = (total: number, partial: number) => {
  return (partial / total) * 100;
};

export interface ProductData {
  productAllocation: number;
  productAmount: number;
  productColor: string;
  productLiquidity?: string;
  productName: string;
  productRate?: number;
  productType: Product;
}

export const PortfolioView: React.FC = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolio, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );

  const productData: ProductData[] = [];

  const totalAmount = Number(portfolio?.amountsInDollars?.totalAmount);

  productData.push({
    productAllocation: getPercentage(
      totalAmount,
      portfolio?.amountsInDollars?.productAmounts?.moneyMarket,
    ),
    productAmount: portfolio?.amountsInDollars?.productAmounts?.moneyMarket,
    productColor: 'productManagedMoneyMarket',
    productLiquidity: '3D',
    productName: 'Managed Money Market',
    productRate: portfolio?.rates?.moneyMarketRate,
    productType: Product.ManagedMoneyMarket,
  });

  productData.push({
    productAllocation: getPercentage(
      totalAmount,
      portfolio?.amountsInDollars?.productAmounts?.managedTreasuries,
    ),
    productAmount:
      portfolio?.amountsInDollars?.productAmounts?.managedTreasuries,
    productColor: 'productManagedTreasuries',
    productLiquidity: '3D',
    productName: 'Managed Treasuries',
    productRate: portfolio?.rates?.managedTreasuriesRate,
    productType: Product.ManagedTreasuries,
  });

  productData.push({
    productAllocation: getPercentage(
      totalAmount,
      portfolio?.amountsInDollars?.productAmounts?.managedIncome,
    ),
    productAmount: portfolio?.amountsInDollars?.productAmounts?.managedIncome,
    productColor: 'productManagedIncome',
    productLiquidity: '3D',
    productName: 'Managed Income',
    productRate: portfolio?.rates?.managedIncomeRate,
    productType: Product.ManagedIncome,
  });

  if (portfolio?.amountsInDollars?.productAmounts?.other > 0) {
    productData.push({
      productAllocation: getPercentage(
        totalAmount,
        portfolio?.amountsInDollars?.productAmounts?.other,
      ),
      productAmount: portfolio?.amountsInDollars?.productAmounts?.other,
      productColor: 'productUninvested',
      productLiquidity: '3D',
      productName: 'Cash',
      productType: Product.Other,
    });
  }

  if (isLoadingBusiness || isLoadingPortfolios) {
    return (
      <Box p={10}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item sm>
        <SectionContainer p={0}>
          <ResponsiveScrollContent widthToSubtract={34}>
            <Box py={2}>
              <ProductTable data={productData} />
            </Box>
          </ResponsiveScrollContent>
        </SectionContainer>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <SectionContainer>
            <SectionContainerTitle title="Pie Chart" />
            <Styled.PieChartContainer
              container
              direction="column"
              justify="center"
            >
              <Grid item>
                <ProductPieChart data={productData} />
              </Grid>
            </Styled.PieChartContainer>
          </SectionContainer>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid item xs={12}>
          <SectionContainer>
            <SectionContainerTitle title="Pie Chart" />
            <Styled.PieChartContainer
              container
              direction="column"
              justify="center"
            >
              <Grid item>
                <ProductPieChart data={productData} />
              </Grid>
            </Styled.PieChartContainer>
          </SectionContainer>
        </Grid>
      </Hidden>
    </Grid>
  );
};
