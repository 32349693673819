import React, { useEffect } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';
import { ReactComponent as IconSymbolActiveManagement } from 'assets/icons/other/symbol-active-management.svg';
import { ProductAllocationProducts } from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useProducts } from 'hooks/products/use-products';
import { calculate12MValueAndYield } from './utils/calculate-12m-value-and-yield';
import { getReturnRangeText } from 'modules/2024-q1/return-range/utils/get-return-range-text';

const productMapper = {
  [ProductAllocationProducts.ManagedMoneyMarket]: {
    content: {
      blurb:
        'Our proprietary quantitative selection model continuously identifies and invests in the top 5-25 government-backed money market funds. Since the underlying fund NAVs are priced at $1, this product has approximately zero principal risk.',
      riskProfile: 'Approximately Zero Principle Risk',
      safety: 'Government Backed Money Market Funds',
      treasureActiveManagement:
        'Managed Money Market is actively managed by the Treasure Investment Team.  This means the underlying holdings are reevaluated on a monthly basis in order to capitalize on new opportunities and mitigate unnecessary risks that emerge within the financial markets.',
    },
    managed: true,
    productAmounts: 'moneyMarket',
    rates: 'moneyMarketRate',
  },
  [ProductAllocationProducts.ManagedTreasuries]: {
    content: {
      blurb:
        'Treasure analyzes the yield curve and chooses the optimal tenor (1- to 12-months) Treasury Bill to purchase for clients.  The tenor demonstrating the greatest potential for price appreciation is purchased for clients.  Depending on market conditions, Treasure also allocates 20% of assets to a 6-month buy-and-hold Treasury Inflation Protected Security (TIPS).  The Treasury Bill and TIPS holdings are reassessed on a monthly basis.',
      riskProfile: 'Very Low Risk',
      safety: 'Treasury Bills and Short-term TIPS',
      treasureActiveManagement:
        'Managed Treasuries is actively managed by the Treasure Investment Team.  This means the underlying holdings are reevaluated on a monthly basis in order to capitalize on new opportunities and mitigate unnecessary risks that emerge within the financial markets.',
    },
    managed: true,
    productAmounts: 'managedTreasuries',
    rates: 'managedTreasuriesRate',
  },
  [ProductAllocationProducts.ManagedIncome]: {
    content: {
      blurb:
        'Treasure tracks approximately 1000 high quality fixed-income mutual funds on a daily basis.  Treasure employs a quantitative fund selection model to choose 5-25 funds.  These funds cover the broad spectrum of fixed-income sectors, exhibiting the best balance of yield and potential for price appreciation.  Managed Income’s allocation is rebalanced on a quarterly basis.',
      riskProfile: 'Low Risk',
      safety: 'Low Volatility Fixed-Income Mutual Funds',
      treasureActiveManagement:
        'Managed Income is actively managed by the Treasure Investment Team.  This means the underlying holdings are reevaluated on a quarterly basis in order to capitalize on new opportunities and mitigate unnecessary risks that emerge within the financial markets.',
    },
    managed: true,
    productAmounts: 'managedIncome',
    rates: 'managedIncomeRate',
  },
  [ProductAllocationProducts.TreasureCash]: {
    content: {
      blurb:
        'Treasure Cash is a high yield business checking account with FDIC insurance up to $250K and automatic rate scaling with Fed interest hikes. Treasure Cash has the highest liquidity of the Treasure product suite.',
      riskProfile: '0 Principle Risk',
      safety: 'FDIC Insured Up To $250K',
      treasureActiveManagement: null,
    },
    managed: false,
    productAmounts: 'cash',
    rates: 'cashRate',
  },
};

const getPercentage = ({
  partialAmount,
  totalAmount,
}: {
  partialAmount: number;
  totalAmount: number;
}) => {
  if (Number.isNaN(totalAmount) || Number.isNaN(partialAmount)) {
    return 0;
  }

  return Number(((partialAmount / totalAmount) * 100).toFixed(2));
};

const InfoGrid = ({
  title,
  value,
}: {
  title: string;
  value: React.ReactNode;
}) => {
  return (
    <Box mb={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {title}
          </Typography>
        </Grid>

        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

interface Props {
  closeDrawer: () => void;
  product: ProductAllocationProducts;
}

export const SidebarProductInformation: React.FC<Props> = ({
  closeDrawer,
  product,
}) => {
  const { trackEvent } = useTracking();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolio, isLoading: isLoadingPortfolio } = usePortfolios(
    business?.Id,
  );
  const { data: products, isLoading: isLoadingProducts } = useProducts(
    business?.Id,
  );

  const hasPortfolio = portfolio?.portfolios.length > 0;
  const managementFee = products?.treasureHighYieldFee || 0;

  const estimated12MYieldAndValue = calculate12MValueAndYield({
    amount:
      portfolio?.amountsInDollars.productAmounts[
        productMapper[product].productAmounts
      ],
    rate: portfolio?.rates[productMapper[product].rates] * 100,
    fee: productMapper[product].managed ? managementFee : 0,
  });

  useEffect(() => {
    trackEvent({
      eventName: TrackEventName.Viewed,
    });
  }, []);

  if (isLoadingBusiness || isLoadingPortfolio || isLoadingProducts) {
    return <Spinner />;
  }

  return (
    <Box px={2.5}>
      <Box
        bgcolor="white"
        height={20}
        position="sticky"
        top={0}
        left={0}
        right={0}
      />

      <Box mb={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {productMapper[product].managed && (
              <Box mb={0.5}>
                <Typography
                  color="brown3a"
                  variant={TypographyVariant.Paragraph2}
                >
                  Treasure Managed
                </Typography>
              </Box>
            )}

            <Typography color="green2" variant={TypographyVariant.Header3}>
              {product}
            </Typography>
          </Grid>

          <Grid item>
            <SVGBox cursor="pointer" onClick={closeDrawer}>
              <IconXCircleGray />
            </SVGBox>
          </Grid>
        </Grid>
      </Box>

      {productMapper[product].content.blurb && (
        <Box mb={4}>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {productMapper[product].content.blurb}
          </Typography>
        </Box>
      )}

      {hasPortfolio && (
        <>
          <Box mb={2}>
            <Typography color="black19" variant={TypographyVariant.Header4}>
              Current Allocation
            </Typography>
          </Box>

          <InfoGrid
            title="Portfolio Share"
            value={
              <Percentage
                decimalScale={2}
                number={getPercentage({
                  partialAmount:
                    portfolio?.productAmounts[
                      productMapper[product].productAmounts
                    ],
                  totalAmount: portfolio?.totalAmount,
                })}
                showExtremePrecision
              />
            }
          />

          <InfoGrid
            title="Investment"
            value={
              <Currency
                number={
                  portfolio?.amountsInDollars.productAmounts[
                    productMapper[product].productAmounts
                  ]
                }
                variant={CurrencyVariant.Full}
              />
            }
          />

          <Box mb={2}>
            <Divider />
          </Box>
        </>
      )}

      <Box mb={2}>
        <Typography color="black19" variant={TypographyVariant.Header4}>
          Product Details
        </Typography>
      </Box>

      <InfoGrid
        title="Liquidity"
        value={
          productMapper[product].managed
            ? '3-5 Business Days'
            : '1 Business Day'
        }
      />

      <InfoGrid
        title="Risk Profile"
        value={productMapper[product].content.riskProfile}
      />

      <InfoGrid title="Safety" value={productMapper[product].content.safety} />

      <InfoGrid
        title="Account Custody"
        value={
          productMapper[product].managed ? 'Apex Clearing' : 'Grasshopper Bank'
        }
      />

      {((productMapper[product].managed && managementFee > 0) ||
        !productMapper[product].managed) && (
        <InfoGrid
          title="Treasure's Management Fee"
          value={
            productMapper[product].managed
              ? `${managementFee === 0 ? '' : managementFee * 100 + ' bps'}`
              : 'Free'
          }
        />
      )}

      {productMapper[product].content.treasureActiveManagement && (
        <>
          <Box mb={2}>
            <Divider />
          </Box>

          <Box mb={2}>
            <Grid container spacing={1}>
              <Grid item>
                <IconSymbolActiveManagement />
              </Grid>

              <Grid item>
                <Box position="relative" top={2}>
                  <Typography
                    color="green2"
                    variant={TypographyVariant.Header5}
                  >
                    Treasure Active Management
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {productMapper[product].content.treasureActiveManagement}
            </Typography>
          </Box>
        </>
      )}

      <Box
        bgcolor="white"
        height={20}
        position="sticky"
        bottom={0}
        left={0}
        right={0}
      />
    </Box>
  );
};
